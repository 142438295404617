import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import emailjs from '@emailjs/browser';
import styles from './ContactScreen.module.scss';

// 예시 배경 이미지를 사용하세요. 실제 이미지로 교체 필요
import contactBgImage from '../../assets/ex6.jpg'; // 실제 경로로 수정 필요

interface ContactProps {
  backgroundColor?: string;
  textColor?: string;
}

const Contact: React.FC<ContactProps> = ({ backgroundColor, textColor }) => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const formRef = useRef<HTMLFormElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const titleRef = useRef<HTMLDivElement>(null);
  
  const [formData, setFormData] = useState({
    companyName: '',
    contactNumber: '',
    email: '',
    availableTime: '',
    budget: '',
    message: '',
    privacyAgreed: false
  });
  
  const [formErrors, setFormErrors] = useState<Partial<Record<keyof typeof formData, string>>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formStatus, setFormStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string;
  }>({ type: null, message: '' });

  // IntersectionObserver를 사용하여 타이틀 요소가 화면에 보이는지 감지
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // 10% 이상 보이면 감지
    };

    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 타이틀 요소에 관찰자 등록
    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    // 컴포넌트 언마운트 시 관찰자 해제
    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
    };
  }, []);

  // 사용자가 다시 입력을 시작하면 오류 메시지 초기화
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      setFormErrors({});
    }
  }, [formData]);

  // 전화번호 자동 서식 함수
  const formatPhoneNumber = (value: string): string => {
    // 숫자가 아닌 모든 문자 제거
    const digits = value.replace(/\D/g, '');
    
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 7) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7, 11)}`;
    }
  };

  // 입력 필드 변경 핸들러
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    // 전화번호 서식 특별 처리
    if (name === 'contactNumber') {
      setFormData({
        ...formData,
        [name]: formatPhoneNumber(value)
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  // 체크박스 변경 핸들러
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });
  };

  // 폼 유효성 검사
  const validateForm = (): boolean => {
    const errors: Partial<Record<keyof typeof formData, string>> = {};
    
    // 회사명 유효성 검사
    if (!formData.companyName.trim()) {
      errors.companyName = '회사명은 필수 입력 항목입니다.';
    }

    // 이메일 유효성 검사
    if (!formData.email.trim()) {
      errors.email = '이메일은 필수 입력 항목입니다.';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        errors.email = '유효한 이메일 주소를 입력해주세요.';
      }
    }

    // 연락처 유효성 검사 (있을 경우만)
    if (formData.contactNumber.trim()) {
      const phoneRegex = /^\d{3}-\d{3,4}-\d{4}$|^\d{3}-\d{4}-\d{4}$/;
      if (!phoneRegex.test(formData.contactNumber)) {
        errors.contactNumber = '유효한 전화번호 형식이 아닙니다. (예: 010-1234-5678)';
      }
    }

    // 문의 내용 유효성 검사
    if (!formData.message.trim()) {
      errors.message = '문의 내용은 필수 입력 항목입니다.';
    }

    // 개인정보 처리방침 동의 검사
    if (!formData.privacyAgreed) {
      errors.privacyAgreed = '개인정보 처리방침에 동의해주세요.';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // 모달 토글
  const toggleModal = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    setIsModalOpen(!isModalOpen);
  };

  // 폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // 폼 유효성 검사
    if (!validateForm()) {
      setFormStatus({
        type: 'error',
        message: '입력 내용을 확인해주세요.'
      });
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // EmailJS를 사용하여 폼 데이터 전송
      const result = await emailjs.sendForm(
        'service_vv6v1l6',
        'template_xoc9p5c',
        formRef.current!,
        '8wmCZyJhO6K-Qeppx'
      );
      
      if (result.text === 'OK') {
        setFormStatus({
          type: 'success',
          message: '문의가 성공적으로 접수되었습니다. 곧 연락드리겠습니다.'
        });
        
        // 폼 초기화
        setFormData({
          companyName: '',
          contactNumber: '',
          email: '',
          availableTime: '',
          budget: '',
          message: '',
          privacyAgreed: false
        });
      }
    } catch (error) {
      console.error('이메일 전송 중 오류 발생:', error);
      setFormStatus({
        type: 'error',
        message: '문의 접수 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // 개인정보 처리방침 모달
  const PrivacyPolicyModal = () => (
<div className={`${styles.modalOverlay} ${isModalOpen ? styles.active : ''}`}>
  <div className={`${styles.modalContent} ${mode === 'dark' ? styles.dark : ''}`}>
      <div className={styles.modalHeader}>
        <h2>개인정보처리방침</h2>
        <button className={styles.closeButton} onClick={toggleModal}>×</button>
      </div>
      <div className={styles.modalBody}>
        <p>미니마이즈(이하 '회사'라 한다)는 개인정보 보호법 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다.</p>
        
        <h3>제1조 (개인정보의 처리목적)</h3>
        <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
        <p>1. 홈페이지 회원 가입 및 관리<br />
        회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정 이용 방지, 만 14세 미만 아동의 개인정보처리 시 법정대리인의 동의 여부 확인, 각종 고지․통지, 고충 처리 등을 목적으로 개인정보를 처리합니다.</p>
        <p>2. 재화 또는 서비스 제공<br />
        물품 배송, 서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금 결제 및 정산, 채권추심 등을 목적으로 개인정보를 처리합니다.</p>
        <p>3. 고충 처리<br />
        민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리 결과 통보 등의 목적으로 개인정보를 처리합니다.</p>
        
        <h3>제2조 (개인정보의 처리 및 보유기간)</h3>
        <p>① 회사는 법령에 따른 개인정보 보유, 이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용 기간 내에서 개인정보를 처리, 보유합니다.<br />
        ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
        <p>1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴 시까지<br />
        다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지<br />
        1) 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사 종료 시까지<br />
        2) 홈페이지 이용에 따른 채권 및 채무관계 잔존 시에는 해당 채권, 채무 관계 정산 시까지</p>
        
        <h3>제5조(이용자 및 법정대리인의 권리와 그 행사 방법)</h3>
        <p>① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br />
        1. 개인정보 열람 요구<br />
        2. 오류 등이 있을 경우 정정 요구<br />
        3. 삭제요구<br />
        4. 처리정지 요구<br />
        ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.<br />
        ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br />
        ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br />
        ⑤ 정보주체는 개인정보 보호법 등 관계 법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니 됩니다.</p>
        
        <h3>제6조(처리하는 개인정보 항목)</h3>
        <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
        <p>1. 홈페이지 회원 가입 및 관리<br />
        필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소<br />
        선택항목 : 관심 분야, 거래 정보</p>
        <p>2. 재화 또는 서비스 제공<br />
        필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소, API정보, 광고매체 계정정보<br />
        선택항목 : 관심 분야, 거래 정보</p>
        <p>3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.<br />
        IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등</p>
        
        <h3>제7조(개인정보의 파기)</h3>
        <p>① 회사는 개인정보 보유 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br />
        ② 정보주체로부터 동의받은 개인정보 보유 기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br />
        ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br />
        1. 파기 절차<br />
        회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br />
        2. 파기 방법<br />
        회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</p>
        
        <h3>제8조(개인정보의 안전성 확보조치)</h3>
        <p>회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 하고 있습니다.<br />
        1. 관리적 조치 : 내부관리계획 수립 및 시행, 정기적 직원 교육 등<br />
        2. 기술적 조치 : 개인정보처리시스템 등의 접근 권한 관리, 접근통제시스템 설치, 고유 식별정보 등의 암호화, 보안프로그램 설치<br />
        3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제</p>
        
        <h3>제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</h3>
        <p>① 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.<br />
        ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에 보내는 소량의 정보이며 이용자들의 컴퓨터 내의 하드디스크에 저장되기도 합니다.<br />
        가. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br />
        나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구{'>'}인터넷 옵션{'>'}개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.<br />
        다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>
        
        <h3>제10조(개인정보 보호책임자)</h3>
        <p>① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만 처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
        <p>▶ 개인정보 보호책임자<br />
        성명 : 방재희<br />
        직책 : 대표<br />
        연락처 : biddingwin@naver.com<br />
        ※ 개인정보 보호 담당부서로 연결됩니다.</p>
        <p>▶ 개인정보 보호 담당부서<br />
        부서명 : 개발팀<br />
        담당자 : 방재희<br />
        연락처 : biddingwin@naver.com</p>
        <p>② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만 처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.</p>
        
        <h3>제11조(개인정보 열람청구)</h3>
        <p>정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다.</p>
        <p>▶ 개인정보 열람청구 접수․처리 부서<br />
        부서명 : 개발팀<br />
        담당자 : 방재희<br />
        연락처 : biddingwin@naver.com</p>
        
        <h3>제12조(권익침해 구제 방법)</h3>
        <p>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</p>
        <p>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)<br />
        - 소관 업무 : 개인정보 침해사실 신고, 상담 신청<br />
        - 홈페이지 : privacy.kisa.or.kr<br />
        - 전화 : (국번없이) 118<br />
        - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터</p>
        <p>▶ 개인정보 분쟁조정위원회<br />
        - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br />
        - 홈페이지 : www.kopico.go.kr<br />
        - 전화 : (국번없이) 1833-6972<br />
        - 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층</p>
        <p>▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)<br />
        ▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)</p>
        
        <h3>제13조(개인정보 처리방침 시행 및 변경)</h3>
        <p>이 개인정보 처리방침은 2021. 09. 15부터 적용됩니다.</p>
      </div>
      <div className={styles.modalFooter}>
        <button className={styles.closeButton} onClick={toggleModal}>닫기</button>
      </div>
    </div>
  </div>
  );

  return (
    <div className={styles.contactSection} style={{ backgroundColor, color: textColor }}>
      {/* 타이틀 섹션 - Work1 스타일과 동일하게 적용 */}
      <div 
        ref={titleRef} 
        className={`${styles.titleSection} ${titleVisible ? styles.visible : ''}`}
      >
        <h1 className={styles.title}>문의하기</h1>
        <div className={styles.overlay}></div>
        <img 
          src={contactBgImage} 
          alt="미니마이즈 문의하기" 
          className={styles.backgroundImage} 
        />
      </div>
      
      <div className={styles.contactContainer}>
        <p className={styles.subtitle}>
          <span className={styles.impact}>문의주셔서</span> 감사합니다.<br />
          <span className={styles.impact}>전문적인 상담과 맞춤형 솔루션</span>으로<br /> 
          귀사의 디지털 전환을 도와드리겠습니다.
        </p>

        <div className={styles.formSection}>
          <form ref={formRef} onSubmit={handleSubmit} className={styles.contactForm}>
            <div className={styles.formGroup}>
              <label htmlFor="companyName">회사명 <span className={styles.required}>*</span></label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
                placeholder="회사명을 입력해주세요"
                className={formErrors.companyName ? styles.inputError : ''}
              />
              {formErrors.companyName && <div className={styles.errorMessage}>{formErrors.companyName}</div>}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="contactNumber">연락처</label>
              <input
                type="tel"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                placeholder="연락 가능한 전화번호를 입력해주세요"
                className={formErrors.contactNumber ? styles.inputError : ''}
              />
              {formErrors.contactNumber && <div className={styles.errorMessage}>{formErrors.contactNumber}</div>}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">이메일 <span className={styles.required}>*</span></label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="이메일 주소를 입력해주세요"
                className={formErrors.email ? styles.inputError : ''}
              />
              {formErrors.email && <div className={styles.errorMessage}>{formErrors.email}</div>}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="availableTime">상담 가능 시간</label>
              <input
                type="text"
                id="availableTime"
                name="availableTime"
                value={formData.availableTime}
                onChange={handleChange}
                placeholder="예: 평일 오전 10시~12시"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="budget">예산 범위</label>
              <select
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
              >
                <option value="">예산 범위를 선택해주세요</option>
                <option value="500만원 미만">500만원 미만</option>
                <option value="500만원~1000만원">500만원~1000만원</option>
                <option value="1000만원~2000만원">1000만원~2000만원</option>
                <option value="2000만원~5000만원">2000만원~5000만원</option>
                <option value="5000만원 이상">5000만원 이상</option>
                <option value="협의 필요">협의 필요</option>
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="message">문의 내용 <span className={styles.required}>*</span></label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={5}
                placeholder="문의하실 내용을 자세히 적어주세요"
                className={formErrors.message ? styles.inputError : ''}
              ></textarea>
              {formErrors.message && <div className={styles.errorMessage}>{formErrors.message}</div>}
            </div>

            <div className={styles.formGroup}>
              <div className={styles.privacyRow}>
                <label className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name="privacyAgreed"
                    checked={formData.privacyAgreed}
                    onChange={handleCheckboxChange}
                  />
                  <span className={styles.checkmark}></span>
                  <span className={styles.checkboxLabel}>
                    <a href="#" className={styles.privacyLink} onClick={toggleModal}>개인정보 처리방침</a>에 동의합니다. <span className={styles.required}>*</span>
                  </span>
                </label>
              </div>
              {formErrors.privacyAgreed && <div className={styles.errorMessage}>{formErrors.privacyAgreed}</div>}
            </div>

            {formStatus.message && (
              <div className={`${styles.formStatus} ${formStatus.type === 'success' ? styles.success : styles.error}`}>
                {formStatus.message}
              </div>
            )}

            <div className={styles.submitContainer}>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? '전송 중...' : '문의 보내기'}
              </button>
            </div>
          </form>
        </div>
      </div>
      
      {/* 개인정보 처리방침 모달 */}
      {isModalOpen && <PrivacyPolicyModal />}
    </div>
  );
};

export default Contact;