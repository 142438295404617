import React, { useEffect, useState, useRef } from 'react';
import styles from './Section1.module.scss';

const Section1: React.FC = () => {
  const [showTitle, setShowTitle] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const [shadowComplete, setShadowComplete] = useState(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const sectionRef = useRef<HTMLElement>(null);
  
  // 화면 크기 감지
  const [windowWidth, setWindowWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // 초기 로드 시 실행

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // 화면 크기에 따라 애니메이션 지연 시간 조정
    const initialDelay = windowWidth <= 768 ? 300 : 500;
    
    // 타이틀 애니메이션 시작 (지연 후)
    const titleTimer = setTimeout(() => {
      setShowTitle(true);
    }, initialDelay);

    // 그림자 애니메이션 시작
    const shadowTimer = setTimeout(() => {
      setShowShadow(true);
    }, initialDelay + 1500);
    
    // 그림자 애니메이션 완료 (튀어나오는 효과를 위한 상태)
    const shadowCompleteTimer = setTimeout(() => {
      setShadowComplete(true);
    }, initialDelay + 2300);
    
    // 스크롤 표시기는 모든 애니메이션이 끝난 후 표시
    const scrollIndicatorTimer = setTimeout(() => {
      setShowScrollIndicator(true);
    }, initialDelay + 3000);

    return () => {
      clearTimeout(titleTimer);
      clearTimeout(shadowTimer);
      clearTimeout(shadowCompleteTimer);
      clearTimeout(scrollIndicatorTimer);
    };
  }, [windowWidth]);

  // 스크롤 시 애니메이션 관리
  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current) return;
      
      const scrollPosition = window.scrollY;
      // 스크롤 위치에 따라 스크롤 표시기 숨기기
      if (scrollPosition > 100) {
        setShowScrollIndicator(false);
      } else {
        setShowScrollIndicator(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section ref={sectionRef} className={styles.section1}>
      <div className={styles.titleContainer}>
        <h2 className={`${styles.letsTitle} ${showTitle ? styles.visible : ''}`}>
          Let's
        </h2>
        <h1 className={`${styles.minimizeTitle} ${showTitle ? styles.visible : ''}`}>
          MINIMIZE
          <span className={`${styles.titleShadow} ${showShadow ? styles.visible : ''} ${shadowComplete ? styles.popOut : ''}`}>
            MINIMIZE
          </span>
        </h1>
        
        <div className={`${styles.scrollIndicator} ${showScrollIndicator ? styles.visible : ''}`}>
          <div className={styles.scrollDown}>
            <div className={styles.arrowContainer}>
              <div className={styles.movingArrow}></div>
            </div>
            <span className={styles.scrollText}>Scroll</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;