import React, { useEffect, useState, useRef } from 'react';
import styles from './Section2.module.scss';
// 이미지 불러오기
import ex1Image from '../../assets/mainimg1.png';
import ex2Image from '../../assets/mainimg2.png';
import ex3Image from '../../assets/mainimg3.png';
import ex4Image from '../../assets/mainimg4.png';

interface ContentItem {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  bulletPoints: string[]; // 추가된 말머리 기호 항목들
}

const Section2: React.FC = () => {
  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const sectionRef = useRef<HTMLElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  // 콘텐츠 데이터
  const contentItems: ContentItem[] = [
    {
      id: 1,
      title: "마케팅 자동화",
      description: "광고 분석부터 입찰까지,\n반복되는 마케팅 업무를 자동화합니다.",
      imageUrl: ex1Image,
      bulletPoints: [
        "리포트 자동 생성 및 공유 (Google Sheets, Slack 등)",
        "키워드 입찰 자동화 (네이버 키워드광고 API 연동)",
        "스마트스토어/쇼핑몰 관리 자동화 (재고, 주문, 리뷰 등)"
      ]
    },
    {
      id: 2,
      title: "CRM 업무 자동화",
      description: "고객을 더 잘 이해하고,\n덜 반복적으로 관리할 수 있도록\n고객관리 시스템을 자동화합니다.",
      imageUrl: ex2Image,
      bulletPoints: [
        "상담 기록 및 후속 조치",
        "고객 상태관리, 소구기존",
        "CRM 환경(엑셀, 웹툴 등) 연동 개발"
      ]
    },
    {
      id: 3,
      title: "물류/배송 자동화",
      description: "복잡한 물류 프로세스를\n플랫폼에 맞게 자동화합니다.",
      imageUrl: ex3Image,
      bulletPoints: [
        "해외 매체 소싱 자동화",
        "쿠팡 WMS 자동 입출고 처리",
        "네이버 스마트스토어 송장 등록 및 상태 추적 자동화"
      ]
    },
    {
      id: 4,
      title: "데이터 수집·분석·시각화",
      description: "단순한 시스템만 만드는 것이 아닙니다.\n데이터를 수집하고, 처리하고, 보이게 만드는 일도 잘합니다.",
      imageUrl: ex4Image, // 이미지 재사용
      bulletPoints: [
        "다양한 사이트의 정보 자동 수집 (크롤링/스크래핑)",
        "실시간 대시보드 구축 (업무/성과 모니터링)",
        "API 통합 및 시각화 시스템 개발"
      ]
    }
  ];

  // 화면 크기 감지
  const [windowWidth, setWindowWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  // 실제 사용할 디바이스 타입을 결정하는 함수
  const getDeviceType = () => {
    if (windowWidth <= 480) return 'mobile-small';
    if (windowWidth <= 767) return 'mobile';
    if (windowWidth <= 1024) return 'tablet';
    if (windowWidth <= 1440) return 'desktop-small';
    return 'desktop';
  };

  const deviceType = getDeviceType();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // IntersectionObserver를 사용하여 요소가 화면에 보이는지 감지
  useEffect(() => {
    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1
    });

    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    // 콘텐츠 항목 관찰자 - 화면 크기에 따라 threshold 값 조정
    const getThreshold = () => {
      if (windowWidth <= 480) return 0.03; // 아주 작은 모바일
      if (windowWidth <= 767) return 0.05; // 모바일
      if (windowWidth <= 1024) return 0.08; // 태블릿
      return 0.1; // 데스크톱
    };

    const itemOptions = {
      root: null,
      rootMargin: deviceType === 'mobile-small' ? '0px 0px -50px 0px' : '0px',
      threshold: getThreshold()
    };

    const itemObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const id = Number(entry.target.getAttribute('data-id'));
          setVisibleItems(prev => {
            if (!prev.includes(id)) {
              return [...prev, id];
            }
            return prev;
          });
        }
      });
    }, itemOptions);

    // 각 항목에 observer 등록
    itemRefs.current.forEach(ref => {
      if (ref) itemObserver.observe(ref);
    });

    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
      itemRefs.current.forEach(ref => {
        if (ref) itemObserver.unobserve(ref);
      });
    };
  }, [windowWidth, deviceType]);

  // 디바이스 타입에 따른 스타일 조정
  const getContainerStyle = () => {
    let paddingHorizontal = '0';
    
    switch (deviceType) {
      case 'mobile-small':
        paddingHorizontal = '10px';
        break;
      case 'mobile':
        paddingHorizontal = '15px';
        break;
      case 'tablet':
        paddingHorizontal = '30px';
        break;
      case 'desktop-small':
        paddingHorizontal = '40px';
        break;
      default:
        paddingHorizontal = '50px';
    }
    
    return { padding: `0 ${paddingHorizontal}` };
  };

  // 콘텐츠 행의 간격 조정
  const getContentRowStyle = (id: number) => {
    let marginBottom = '350px';
    
    if (deviceType === 'mobile-small') {
      marginBottom = '80px';
    } else if (deviceType === 'mobile') {
      marginBottom = '120px';
    } else if (deviceType === 'tablet') {
      marginBottom = '160px';
    } else if (deviceType === 'desktop-small') {
      marginBottom = '250px';
    }
    
    return { 
      marginBottom: id === contentItems.length ? '100px' : marginBottom,
      margin: "0 auto", 
      padding: "0",
      width: "100%",
      boxSizing: "border-box" as const
    };
  };

  // 이미지 컨테이너 스타일 조정
  const getImageContainerStyle = () => {
    let padding = '40px';
    
    if (deviceType === 'mobile-small') {
      padding = '12px';
    } else if (deviceType === 'mobile') {
      padding = '15px';
    } else if (deviceType === 'tablet') {
      padding = '20px';
    } else if (deviceType === 'desktop-small') {
      padding = '30px';
    }
    
    return { 
      margin: "0 auto", 
      padding: padding,
      width: "100%",
      boxSizing: "border-box" as const
    };
  };

  // 텍스트 컨테이너 스타일 조정
  const getTextContainerStyle = () => {
    let padding = '40px';
    
    if (deviceType === 'mobile-small') {
      padding = '12px';
    } else if (deviceType === 'mobile') {
      padding = '15px';
    } else if (deviceType === 'tablet') {
      padding = '20px';
    } else if (deviceType === 'desktop-small') {
      padding = '30px';
    }
    
    return { 
      margin: "0 auto", 
      padding: padding,
      width: "100%",
      boxSizing: "border-box" as const
    };
  };

  // 제목 스타일 조정
  const getTitleStyle = () => {
    let fontSize = '2.5rem';
    
    if (deviceType === 'mobile-small') {
      fontSize = '1.3rem';
    } else if (deviceType === 'mobile') {
      fontSize = '1.6rem';
    } else if (deviceType === 'tablet') {
      fontSize = '1.9rem';
    } else if (deviceType === 'desktop-small') {
      fontSize = '2.2rem';
    }
    
    return { 
      paddingLeft: 0, 
      textIndent: 0,
      fontSize: fontSize,
      marginBottom: deviceType === 'mobile-small' ? '10px' : deviceType === 'mobile' ? '12px' : deviceType === 'tablet' ? '15px' : '20px'
    };
  };

  // 설명 스타일 조정
  const getDescriptionStyle = () => {
    let fontSize = '1.2rem';
    
    if (deviceType === 'mobile-small') {
      fontSize = '0.85rem';
    } else if (deviceType === 'mobile') {
      fontSize = '0.95rem';
    } else if (deviceType === 'tablet') {
      fontSize = '1.05rem';
    } else if (deviceType === 'desktop-small') {
      fontSize = '1.15rem';
    }
    
    return { 
      paddingLeft: 0, 
      textIndent: 0,
      fontSize: fontSize,
      lineHeight: deviceType === 'mobile-small' ? '1.45' : deviceType === 'mobile' ? '1.6' : '1.8'
    };
  };

  // 목록 스타일 조정
  const getBulletPointsStyle = () => {
    return { 
      paddingLeft: 0, 
      marginLeft: 0,
      maxWidth: windowWidth <= 1024 ? '100%' : '80%'
    };
  };

  // 목록 항목 스타일 조정
  const getBulletPointItemStyle = () => {
    let fontSize = '1.1rem';
    let paddingLeft = '25px';
    
    if (deviceType === 'mobile-small') {
      fontSize = '0.8rem';
      paddingLeft = '16px';
    } else if (deviceType === 'mobile') {
      fontSize = '0.9rem';
      paddingLeft = '18px';
    } else if (deviceType === 'tablet') {
      fontSize = '1rem';
      paddingLeft = '20px';
    } else if (deviceType === 'desktop-small') {
      fontSize = '1.05rem';
      paddingLeft = '22px';
    }
    
    return { 
      paddingLeft: paddingLeft, 
      textIndent: 0,
      fontSize: fontSize,
      marginBottom: deviceType === 'mobile-small' ? '6px' : deviceType === 'mobile' ? '8px' : deviceType === 'tablet' ? '10px' : '12px'
    };
  };

  return (
    <section ref={sectionRef} className={styles.section2}>
      <div className={styles.container} style={getContainerStyle()}>
        <div ref={titleRef} className={styles.sectionTitle}>
          
        </div>
        
        {contentItems.map((item, index) => {
          const isVisible = visibleItems.includes(item.id);
          const isEvenId = item.id % 2 === 0;
          const isLastItem = index === contentItems.length - 1;
          
          return (
            <div 
              key={item.id}
              ref={(el: HTMLDivElement | null): void => {
                itemRefs.current[index] = el;
              }}
              data-id={item.id}
              className={`${styles.contentRow} ${isEvenId && windowWidth > 1024 ? styles.reverse : ''} ${isVisible ? styles.visible : ''} ${item.id === 2 ? styles.specialItem : ''}`}
              style={getContentRowStyle(isLastItem ? 999 : item.id)}
            >
              {/* 이미지 컨테이너 */}
              <div 
                className={`${styles.imageContainer} ${isVisible ? styles.animateImage : ''}`} 
                style={getImageContainerStyle()}
              >
                <div className={styles.imagePlaceholder} style={{
                  height: deviceType === 'mobile-small' ? '200px' : 
                         deviceType === 'mobile' ? '270px' : 
                         deviceType === 'tablet' ? '350px' : 
                         deviceType === 'desktop-small' ? '420px' : '450px'
                }}>
                  {item.imageUrl ? (
                    <img src={item.imageUrl} alt={item.title} loading="lazy" />
                  ) : (
                    <span>이미지 {item.id}</span>
                  )}
                </div>
              </div>
              
              {/* 텍스트 컨텐츠 */}
              <div 
                className={`${styles.textContent} ${isVisible ? styles.animateText : ''}`} 
                style={getTextContainerStyle()}
              >
                <h3 style={getTitleStyle()}>{item.title}</h3>
                <p style={getDescriptionStyle()}>{item.description}</p>
                
                {/* 말머리 기호 항목 */}
                <ul className={styles.bulletPoints} style={getBulletPointsStyle()}>
                  {item.bulletPoints.map((point, i) => (
                    <li key={i} style={getBulletPointItemStyle()}>{point}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Section2;