import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './AboutScreen.module.scss';
import Section3 from '../../components/Main/Section3';
import Contact from '../../components/Contact/Contact';
import ex1 from '../../assets/aboutmain2.png';
import ex2 from '../../assets/aboutmain3.png';
import ex3 from '../../assets/aboutmain1.png';
import heroImage from '../../assets/ex8.jpg';

const AboutScreen: React.FC = () => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const [titleVisible, setTitleVisible] = useState(false);
  const [isMiddleSectionVisible, setIsMiddleSectionVisible] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const middleSectionRef = useRef<HTMLDivElement>(null);
  const contentBlockRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // 페이지 최상단으로 스크롤
    window.scrollTo(0, 0);
    
    // 초기 타이틀 애니메이션 (약간 지연 후 표시)
    const titleTimer = setTimeout(() => {
      setTitleVisible(true);
    }, 300);

    // 중간 섹션 스크롤 애니메이션을 위한 Intersection Observer
    const middleSectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsMiddleSectionVisible(true);
          middleSectionObserver.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1 // 10%가 보이면 활성화
      }
    );

    // 각 콘텐츠 블록에 대한 개별 애니메이션 옵저버 (선택적 개선)
    const contentBlockObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 요소가 보이면 애니메이션 클래스 추가
            entry.target.classList.add(styles.visible);
            // 한 번 표시되면 더 이상 관찰할 필요 없음
            contentBlockObserver.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: '-50px',
        threshold: 0.15 // 15%가 보이면 활성화
      }
    );

    // 옵저버 설정
    if (middleSectionRef.current) {
      middleSectionObserver.observe(middleSectionRef.current);
    }

    // 정리 함수
    return () => {
      clearTimeout(titleTimer);
      middleSectionObserver.disconnect();
      contentBlockObserver.disconnect();
    };
  }, []);

  return (
    <div className={`${styles.aboutScreen} ${mode === 'light' ? styles.light : styles.dark}`}>
      <div className={styles.background}>
        {/* 타이틀 섹션 */}
        <div 
          ref={titleRef} 
          className={`${styles.titleSection} ${titleVisible ? styles.visible : ''}`}
        >
          <h1 className={styles.title}>About</h1>
          <div className={styles.overlay}></div>
          <img 
            src={heroImage} 
            alt="고객관리 CRM 프로그램" 
            className={styles.backgroundImage} 
          />
        </div>
        
        {/* 중간 섹션 - 텍스트와 이미지 배치 */}
        <div 
          ref={middleSectionRef} 
          className={`${styles.middleSection} ${isMiddleSectionVisible ? styles.visible : ''}`}
        >
          <div className={styles.container}>
            {/* 첫 번째 콘텐츠 블록 - 이미지(왼쪽) + 텍스트(오른쪽) */}
            <div 
              className={styles.contentBlock}
              ref={(el: HTMLDivElement | null) => { contentBlockRefs.current[0] = el }}
            >
              <div 
                className={`${styles.imageBlock} ${styles.firstImage}`} 
                style={{ backgroundImage: `url(${ex1})` }}
                aria-label="단순한 자동화가 아닌 더 나은 가능성을 보여주는 이미지"
              ></div>
              
              <div className={`${styles.textBlock} ${styles.textRight}`}>
                <h3 className={styles.title}>단순한 자동화가 아닙니다</h3>
                <p className={styles.description}>당신의 방식에 더 나은 가능성을 더합니다</p>
              </div>
            </div>
            
            {/* 두 번째 콘텐츠 블록 - 원래 텍스트(왼쪽) + 이미지(오른쪽) 이지만 HTML 순서를 바꿔서 배치 */}
            {/* 데스크탑에서는 CSS order 속성으로 순서 조정 */}
            <div 
              className={styles.contentBlock}
              ref={(el: HTMLDivElement | null) => { contentBlockRefs.current[1] = el }}
            >
              {/* 이미지 먼저 배치 (모바일에서는 이 순서대로 표시됨) */}
              <div 
                className={`${styles.imageBlock} ${styles.secondImage}`} 
                style={{ backgroundImage: `url(${ex2})` }}
                aria-label="질문과 제안을 통한 개선 프로세스 이미지"
              ></div>
              
              {/* 텍스트 다음에 배치 */}
              <div className={`${styles.textBlock} ${styles.textLeft}`}>
                <h3 className={styles.title}>당신의 방식에,<br></br>우리는 질문합니다</h3>
                <p className={styles.description}>더 나은 방법이 있다면, 우리는 제안합니다</p>
              </div>
            </div>
            
            {/* 세 번째 콘텐츠 블록 - 이미지(왼쪽) + 텍스트(오른쪽) */}
            <div 
              className={styles.contentBlock}
              ref={(el: HTMLDivElement | null) => { contentBlockRefs.current[2] = el }}
            >
              <div 
                className={`${styles.imageBlock} ${styles.thirdImage}`} 
                style={{ backgroundImage: `url(${ex3})` }}
                aria-label="단순하고 쉬운 방식을 보여주는 이미지"
              ></div>
              
              <div className={`${styles.textBlock} ${styles.textRight}`}>
                <h3 className={styles.title}>더 단순한 길을 함께 만듭니다</h3>
                <p className={styles.description}>복잡한 것을 단순하게, 어려운 것을 쉽게</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Section3 컴포넌트 */}
      <Section3 />
      
      {/* Contact 컴포넌트 */}
      <Contact />
    </div>
  );
};

export default AboutScreen;